<template>
  <CBox py="1em" px="1em" minH="500px" borderRadius="10px">
    <CText
      fontFamily="Montserrat"
      fontStyle="normal"
      fontSize="24px"
      fontWeight="600"
      mb="1em"
      color="fontMain"
    >
      Challenge List
    </CText>
    <!-- <CList styleType="disc" spacing="3"> -->
    <CBox>
      <CGrid
        :templateColumns="[
          '12fr',
          '6fr 6fr',
          '2fr 2fr 2fr 2fr',
          '3fr 3fr 3fr 3fr',
        ]"
        :gap="[2, 2, 6, 6]"
      >
        <CBox
          bg="white"
          p="1em"
          borderLeft="2px solid"
          borderColor="main"
          borderRadius="3px"
          fontWeight="500"
          boxShadow="0px 4px 10px rgba(0, 0, 0, 0.1)"
          class="pointer"
          v-for="(v, i) in currentAppTypes"
          :key="i"
          @click.native="
            $router.push({ name: 'Competition', params: { slug: v.slug } })
          "
        >
          {{ v.name }}
        </CBox>
      </CGrid>
    </CBox>
    <!-- </CList> -->
  </CBox>
</template>

<script>
// @ is an alias to /src
import { GET_APP_TYPES } from "@/store/app-types.module";
import { CBox } from "@chakra-ui/vue";

export default {
  name: "Home",
  components: {
    CBox,
  },
  mounted() {
    this.$store.dispatch(GET_APP_TYPES);
  },
  computed: {
    currentAppTypes() {
      return this.$store.getters.currentAppTypes;
    },
  },
};
</script>
